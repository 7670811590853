<template>
<div class="p-grid">
    <div class="home">
        <div class="features" style="text-align: center;background-image: url('./images/prefondo4.png');">
            <h3 style="color:#ffcc2a;">{{$t('Elija su mejor cobertura para usted')}}</h3>
            <h5 style="color:#ffff;">{{$t('Hazlo en SaBeé ”Protección financiera a la medida de sus necesidades”')}}</h5>
            <div class="p-grid" >
                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <img alt="components" src="../assets/images/HomeInsurance.webp" width="220" height="110"/>
                        <div class="feature-card-detail">
                            <div class="feature-name">{{$t('Fire Insurance')}}</div>
                            <img style="width: 20%;" src="../assets/images/IconHome.webp" width="32" height="32"/>
                            <br><Button :disabled ="$store.state.loading" :label="$t('Cotización')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(1)"></Button>
                            <p>{{$t('Fire Insurance Desc')}}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <img alt="opensource" src="../assets/images/VehicleInsurance.webp" width="220" height="110"/>
                        <div class="feature-card-detail">
                            <div class="feature-name">{{$t('Vehicle Insurance')}}</div>
                            <img style="width: 20%;" src="../assets/images/IconVehicle.webp" width="32" height="32"/>
                            <br><Button :disabled ="$store.state.loading" :label="$t('Cotización')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(2)"></Button>
                            <p>{{$t('Vehicle Insurance Desc')}}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <img alt="themes" src="../assets/images/HealthInsurance.webp" width="220" height="110"/>
                        <div class="feature-card-detail">
                            <div class="feature-name">{{$t('Health Insurance')}}</div>
                            <img style="width: 20%;" src="../assets/images/IconHealth.webp" width="32" height="32"/>
                            <br><Button :disabled ="$store.state.loading" :label="$t('Cotización')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(3)"></Button>
                            <p>{{$t('Health Insurance Desc')}}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <img alt="templates" src="../assets/images/BusinessInsurance.webp" width="220" height="110"/>
                        <div class="feature-card-detail">
                            <div class="feature-name">{{$t('Seguro de Proyecto')}}</div>
                            <img style="width: 20%;" src="../assets/images/IconBusiness.webp" width="32" height="32"/>
                            <br><Button :disabled ="$store.state.loading" :label="$t('Cotización')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2" @click="Opcion(4)"></Button>
                            <p>{{$t('Business Insurance Desc')}}</p>
                        </div>
                    </div>
                </div>


          </div>
        </div>
	</div>

	<Dialog v-model:visible="PerfilDialog" :style="{width: size}" :header="$t('Mi Perfil')" :modal="true">
		<br>
        <div class="confirmation-content">
			<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
			<span> {{$t('Actualiza Perfil')}}</span>
		</div>
		<template #footer>
			<Button :disabled ="$store.state.loading" :label="$t('Mi Perfil')" icon="pi pi-check" class="p-button-secondary" @click="Perfil" />
			<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="PerfilDialog = false"/>
		</template>
	</Dialog>



</div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('Cotizacion','Polizas');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";

export default {
	components: {
    },
	data() {
		return {
			i18n: null,
            caso: null,
            perfil: null,
            PerfilDialog: false,
            poliza_incendio: [],
            poliza_vehiculo: [],
            poliza_salud: [],
            poliza_proyecto: [],
            duracion: [],
            cuotas: [],
			url: null,
			size: '60vw',
			size2: '25vw',
			Diag: false,
			DiagIMG: false,
			DiagMENS: false,
			imagen: null,
			mensaje: null
		}
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';this.size2 = '90vw';}
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
            this.poliza_incendio = [];
            this.poliza_vehiculo = [];
            this.poliza_salud = [];
            this.poliza_proyecto = [];
            this.PerfilDialog = false;
            Consulta.Ini().then(result => {
                this.perfil = result.consult.perfil;              
                if (result.consult.poliza_incendio){
                    this.poliza_incendio = result.consult.poliza_incendio;
                }
                if (result.consult.poliza_vehiculo){
                    this.poliza_vehiculo = result.consult.poliza_vehiculo;
                }
                if (result.consult.poliza_salud){
                    this.poliza_salud = result.consult.poliza_salud;
                }
                if (result.consult.poliza_proyecto){
                    this.poliza_proyecto = result.consult.poliza_proyecto;
                }
                this.duracion = result.consult.duracion;
                this.cuotas = result.consult.cuotas;
            }); 
        },
		Ver1(caso,data){
			this.Caso = caso;
			this.ver_mostrar = data;
			this.Diag = true;
		},
        VerPDF(document){
            window.open(url.upload+document+'.pdf', '_blank');
		},
		VerIMG(data){
			this.imagen = data;
			this.DiagIMG = true;
		},
		VerMENS(data){
			this.mensaje = data;
			this.DiagMENS = true;
		},
        Solicitar(product){
			if ((product.duracion==1 && product.cuotas>1) || (product.duracion==2 && product.cuotas>0)) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Cuota'), life: 10000});
			} else {		
                Consulta.Procesar('Solicitar',{
                    id: product.id,
                    duracion: product.duracion,
                    cuotas: product.cuotas,
                    }).then(response => {
                        if (response.result) {
                            this.$toast.add({
                                severity:'success', 
                                summary: 'Éxito', 
                                detail: 'Información Procesada', 
                                life: 3000
                            });
                            this.Mostrar();
                        } else {
                            this.$toast.add({
                                severity:'warn', 
                                summary: 'Advertencia', 
                                detail: 'No fue procesada la solicitud', 
                                life: 3000
                            });
                        }
                    });
            }
        },
        VerCompromiso(product){
			if ((product.duracion==1 && product.cuotas>1) || (product.duracion==2 && product.cuotas>0)) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Cuota'), life: 10000});
			} else {		
                Consulta.Procesar('Compromiso',{
                    poliza: product,
                    duracion: this.duracion[product.duracion].valor,
                    cuotas: this.cuotas[product.cuotas].valor,
                    monto:  (product.evaluacion.primabrutat*this.duracion[product.duracion].valor*(1+this.cuotas[product.cuotas].porcentaje/100)+parseFloat(product.evaluacion.kosten))*(1+parseFloat(product.evaluacion.impuesto)/100),

                }).then(response => {
                    if (response.result) {
                        this.$toast.add({
                            severity:'success', 
                            summary: 'Éxito', 
                            detail: 'Información Procesada', 
                            life: 3000
                        });
                        if(!this.$store.state.paginas.includes('/mypolicies')){
                            const Seg = new API('Autenticacion','Login');         
                            Seg.Procesar(null,{
                                correo:  this.$store.state.correo,
                                clave:   this.$store.state.clave
                            }).then(response => {
                                this.$store.commit('Login',response);
                                this.$store.commit('Menu'); 
                                this.$router.push({ path: '/mypayments'});
                            });
                        } else {
                            this.$router.push({ path: '/mypayments'});
                        }
                    } else {
                        this.$toast.add({
                            severity:'warn', 
                            summary: 'Advertencia', 
                            detail: 'No fue procesada la solicitud', 
                            life: 3000
                        });
                    }
                });
            }
        },
        Opcion(caso){
            if(this.perfil){
                this.$router.push({ path: '/quotation'+caso});
            } else {
                this.PerfilDialog = true;
            }
        },
        Ver(caso,data){
            const doc = new PDF();
            var monto = ((data.evaluacion.primabrutat*this.duracion[data.duracion].valor*(1+this.cuotas[data.cuotas].porcentaje/100)+parseFloat(data.evaluacion.kosten))*(1+parseFloat(data.evaluacion.impuesto)/100));
            doc.Cotizacion(caso,data,this.duracion,this.cuotas,monto);
            //doc.CotizacionVeh(data);
        },
        close() {
            this.caso = null;
        },
        Perfil() {
            this.$router.push({ path: '/profile' });
        },        
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},


	},
	computed: {

    }

}
</script>
